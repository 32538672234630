<template>
  <div class="projects-list">
    <div
      v-for="(category, index) in categories"
      :key="index"
    >
      <h3>{{ category[`title_${this.$i18n.locale}`] }}</h3>

      <table class="table">
        <tbody>
          <tr
            v-for="(project, p_index) in filteredProjects(category.id)"
            :key="p_index"
            @click="setProject(project)"
          >
            <td class="client">
              {{ project.client }}
            </td>
            <td class="title">
              <span>{{ project[`title_${this.$i18n.locale}`] }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    projects: {
      type: Object,
      required: true,
    },
    categories: {
      type: Object,
      required: true,
    },
  },

  methods: {
    setProject(project) {
      // Load the projects
      // Go to the project detail view
      this.$store.commit('setProject', project);
      this.$router.push({ path: `/project/${project.slug}` });
    },

    filteredProjects(category) {
      // This is to filter the projects by category
      const results = [];
      this.projects.forEach((project) => {
        if (project.category === category) {
          results.push(project);
        }
      });
      return results;
    },
  },
};
</script>
