<template>
  <div class="projects-grid">
    <div
      v-for="(category, index) in categories"
      :key="index"
    >
      <h3>{{ category[`title_${this.$i18n.locale}`] }}</h3>
      <!-- row -->
      <div class="row grid">
        <div
          v-for="(project, p_index) in filteredProjects(category.id)"
          :key="p_index"
          class="col-md-4 col-xs-6 portfolio-item"
        >
          <div
            @click="setProject(project)"
          >
            <img
              class="img-responsive"
              :src="`https://storage.googleapis.com/visualspace-nl/${project.thumb}`"
              alt=""
            >
            <div class="title">
              <p>
                {{ project[`title_${this.$i18n.locale}`] }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr v-if="index !== categories.length - 1">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    projects: {
      type: Object,
      required: true,
    },
    categories: {
      type: Object,
      required: true,
    },
  },

  methods: {
    setProject(project) {
      // Load the projects
      // Go to the project detail view
      this.$store.commit('setProject', project);
      this.$router.push({ path: `/project/${project.slug}` });
    },

    filteredProjects(category) {
      // This is to filter the projects by category
      const results = [];
      this.projects.forEach((project) => {
        if (project.category === category) {
          results.push(project);
        }
      });
      return results;
    },
  },
};
</script>
