<template>
  <div class="container">
    <h1>Projects</h1>
    <span
      @click.stop="toggleProjectView"
      class="toggle"
    >
      <a href="#">
        <img
          v-if="toggleProjectGrid"
          :src="require('@/assets/img/toggle-list.svg')"
          alt="#"
        >
        <img
          v-else
          :src="require('@/assets/img/toggle-grid.svg')"
          alt="#"
        >
      </a>
    </span>
    <project-grid
      v-if="toggleProjectGrid"
      :projects="projects"
      :categories="categories"
    />
    <project-list
      v-else
      :projects="projects"
      :categories="categories"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProjectGrid from '@/components/ProjectGrid';
import ProjectList from '@/components/ProjectList';

export default {
  components: {
    ProjectGrid,
    ProjectList,
  },

  data() {
    return {
      toggleProjectGrid: true,
    };
  },

  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      'projects',
      'categories',
    ]),
  },

  methods: {
    toggleProjectView() {
      this.toggleProjectGrid = !this.toggleProjectGrid;
    },
  },
};
</script>

<style lang="scss" scoped>
.toggle {
  width: 20px;
  height: 20px;
  overflow: hidden;
  float: right;
  margin-top: -38px;
  & img {
    position: relative;
    left: 0px;
    top: 0;
    &:hover {
      top:-30px;
    }
  }
}
</style>
